import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.isDragging = false;
    this.startX = 0;
    this.scrollLeft = 0;

    // Event listeners for mouse/touch events on the container
    this.containerTarget.addEventListener("mousedown", this.startDrag.bind(this));
    this.containerTarget.addEventListener("mouseleave", this.stopDrag.bind(this));
    this.containerTarget.addEventListener("mouseup", this.stopDrag.bind(this));
    this.containerTarget.addEventListener("mousemove", this.drag.bind(this));
  }

  startDrag(event) {
    // Ignore drag if the event target has dragIgnore data attribute
    if (event.target.dataset.dragIgnore) {
      event.preventDefault();  // Prevent default drag behavior on images
    }

    this.isDragging = true;
    this.startX = event.pageX || event.touches[0].pageX;
    this.scrollLeft = this.containerTarget.scrollLeft;
  }

  stopDrag() {
    this.isDragging = false;
  }

  drag(event) {
    if (!this.isDragging) return;

    event.preventDefault();
    const x = event.pageX || event.touches[0].pageX;
    const walk = (x - this.startX);
    this.containerTarget.scrollLeft = this.scrollLeft - walk;
  }
}
