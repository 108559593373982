import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-button"
export default class extends Controller {
  static targets = ["button", "checkbox", "multiple"]

  change(event) {
    const multiple = this.multipleTarget.value
    const eventValue = event.target.value
    
    this.checkboxTargets.forEach((checkboxTarget) => {
      let checked = checkboxTarget.checked
      if (multiple == "false" && checkboxTarget.value != eventValue) {
        checked = false
      }
      const buttonTarget = this.buttonTargets.find((buttonTarget) => buttonTarget.dataset.id == checkboxTarget.value)

      if (checked) {
        buttonTarget.classList.remove("bg-transparent")
        buttonTarget.classList.remove("border-white-2")
        buttonTarget.classList.add("bg-purple-2")
        buttonTarget.classList.add("border-purple-1")
      } else {
        checkboxTarget.checked = false
        buttonTarget.classList.add("bg-transparent")
        buttonTarget.classList.add("border-white-2")
        buttonTarget.classList.remove("bg-purple-2")
        buttonTarget.classList.remove("border-purple-1")
      }
    })
  }
}
