import { Controller } from "@hotwired/stimulus";

// Used for sending events saved in rails session
export default class extends Controller {
  static values = {
    eventName: String, // Custom event name,
  };

  connect() {
    const event = this.eventNameValue || "default_session_event_name";
    
    const dataset = this.element.dataset;
    delete dataset.sessionAnalyticsEventNameValue;
    delete dataset.controller
    
    const data = {
      event: event,
      ...dataset, // Include any data attributes from the element
    };

    if (window.dataLayer) {
      window.dataLayer.push(data); // Send event to GTM
      console.log("Event sent to GTM:", data);
    } else {
      console.warn("GTM dataLayer not found.");
    }
  }
}
