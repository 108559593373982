import { Controller } from "@hotwired/stimulus";
import Cropper from "cropperjs";

function targetToFloat(target) {
  return parseFloat(target.value)
}

export default class extends Controller {
  static targets = [
    "imageResult", "form", "xOffsetResult", "yOffsetResult", "widthResult", "heightResult",
    "width", "height",
    "numerator", "denominator",
    "ctaButton", "secondaryCtaButton"
  ]

  loadAndRender() {
    const imageResult = this.imageResultTarget
    const interval = setInterval(() => {
      if (imageResult.complete) {
        clearInterval(interval)
        this.render()
      }
    }, 50)
  }

  disableCtaButton() {
    const ctaButton = this.ctaButtonTarget
    ctaButton.classList.add("bg-white-4")
    ctaButton.classList.remove("bg-purple-1")
    ctaButton.classList.add("cursor-not-allowed")
    ctaButton.classList.remove("cursor-pointer")
    ctaButton.disabled = true
    ctaButton.innerHTML = "Please wait..."

    const secondaryCtaButton = this.secondaryCtaButtonTarget
    secondaryCtaButton.classList.add("invisible")
  }

  render() {
    const imageResult = this.imageResultTarget
    if (imageResult.cropper) imageResult.cropper.destroy()

    const xOffsetResult = this.xOffsetResultTarget
    const yOffsetResult = this.yOffsetResultTarget
    const widthResult = this.widthResultTarget
    const heightResult = this.heightResultTarget

    const height = targetToFloat(this.heightTarget)
    const width = targetToFloat(this.widthTarget)
    const numerator = targetToFloat(this.numeratorTarget)
    const denominator = targetToFloat(this.denominatorTarget)

    let aspectRatio = null
    if (height > width) {
      aspectRatio = denominator / numerator
    } else {
      aspectRatio = numerator / denominator
    }

    const ctaButton = this.ctaButtonTarget

    new Cropper(imageResult, {
      scalable: false,
      zoomable: false,
      movable: false,
      aspectRatio,
      autoCropArea: 1,
      crop(event) {
        xOffsetResult.value = event.detail.x < 0 ? 0 : event.detail.x
        yOffsetResult.value = event.detail.y < 0 ? 0 : event.detail.y
        widthResult.value = event.detail.width < 0 ? 0 : event.detail.width
        heightResult.value = event.detail.height < 0 ? 0 : event.detail.height
      },
      ready() {
        ctaButton.classList.add("bg-purple-1")
        ctaButton.classList.remove("bg-white-4")
        ctaButton.classList.add("cursor-pointer")
        ctaButton.classList.remove("cursor-not-allowed")
        ctaButton.disabled = false
      }
    })
  }
}
