import { Controller } from "@hotwired/stimulus";

// For frontend use
export default class extends Controller {
  static values = {
    eventName: String, // Custom event name,
  };

  trackEvent() {
    const event = this.eventNameValue || "default_event_name";
    
    const dataset = this.element.dataset;
    delete dataset.analyticsEventNameValue;
    
    const data = {
      event: event,
      ...dataset, // Include any data attributes from the element
    };

    if (window.dataLayer) {
      window.dataLayer.push(data); // Send event to GTM
    } else {
      console.warn("GTM dataLayer not found.");
    }
  }
}
