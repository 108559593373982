// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./sell_canvas_prints"
import "trix"
import "@rails/actiontext"

// Google Tag Manager
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PD359M6K');

window.dataLayer = window.dataLayer || [];

document.addEventListener("turbo:load", () => {
  dataLayer.push({ event: 'turbo_load' });
});


// Chat
(function(d,t) {
  var BASE_URL="https://app.chatwoot.com";
  var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
  g.src=BASE_URL+"/packs/js/sdk.js";
  g.defer = true;
  g.async = true;
  s.parentNode.insertBefore(g,s);
  g.onload=function(){
    window.chatwootSDK.run({
      websiteToken: 'enwWnNgkxiRn9bNEYhrtSSfg',
      baseUrl: BASE_URL
    })
  }
})(document,"script");