import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="steps"
export default class extends Controller {
  static targets = ["form", "continueButton"]

  connect() {
    if (this.hasFormTarget) {
      this.validate()
    } else {
      for (const continueButton of this.continueButtonTargets) {
        continueButton.classList.add("invisible")
      }
    }
  }

  reconnect() {
    setTimeout(() => this.connect(), 1); // wait for form to be connected
  }

  continue(e) {
    if (this.isValid()) {
      this.formTarget.noValidate = true;
      this.disable()
      this.pleaseWait()
      if (e.target.dataset.stepsMorph == 'true') {
        this.formTarget.requestSubmit()
      } else {
        this.formTarget.submit() // This doesn't trigger turbo morph
      }
    } else {
      this.validate()
    }
  }
  
  isValid() {
    const form = this.formTarget
    let isValid = true

    Array.from(form.elements).forEach(element => {
      if (element.required) {
        if (element.type === "file") {
          if (element.files.length === 0) {
            isValid = false
          }
        } else if (element.type === "checkbox") {
          const checkboxes = form.querySelectorAll(`input[type="checkbox"][name="${element.name}"]`)
          const isAnyChecked = Array.from(checkboxes).some(checkbox => checkbox.checked)
  
          if (!isAnyChecked) {
            isValid = false
          }
        } else if (!element.classList.contains("trix-input") && !element.value.trim()) {
          isValid = false
        }
      }
    })
    return isValid
  }

  validate() {
    if (this.isValid()) {
      this.enable()
    } else {
      this.disable()
    }
  }

  pleaseWait() {
    for (const continueButton of this.continueButtonTargets) {
      continueButton.innerHTML = `
        Please wait...
      `
    }
  }

  disable() {
    for (const continueButton of this.continueButtonTargets) {
      continueButton.classList.add("bg-white-4")
      continueButton.classList.remove("bg-purple-1")
      continueButton.classList.add("cursor-not-allowed")
      continueButton.classList.remove("cursor-pointer")
      continueButton.classList.remove("hover:opacity-80")
      continueButton.disabled = true
    }
  }

  enable() {
    for (const continueButton of this.continueButtonTargets) {
      continueButton.classList.add("bg-purple-1")
      continueButton.classList.remove("bg-white-4")
      continueButton.classList.add("cursor-pointer")
      continueButton.classList.remove("cursor-not-allowed")
      continueButton.classList.add("hover:opacity-80")
      continueButton.disabled = false
    }
  }
}
