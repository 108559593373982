import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["header", "content", "arrow", "expandedInput"];

  handleClick(event) {
    const clickedHeader = event.currentTarget;
    const ratioId = clickedHeader.dataset.ratioId;
    const clickedContent = this.contentTargets.find(content => content.dataset.ratioId === ratioId);
    const clickedArrow = clickedHeader.querySelector('.arrow-icon');
    const isExpanded = clickedContent.classList.contains('expanded');

    if (!isExpanded) {
      clickedContent.classList.add('expanded');
      clickedArrow.style.transform = 'rotate(180deg)';
    } else {
      clickedContent.classList.remove('expanded');
      clickedArrow.style.transform = 'rotate(0deg)';
    }

    const expandedIds = this.contentTargets.filter(content => content.classList.contains('expanded')).map(content => content.dataset.ratioId);

    this.expandedInputTargets.forEach(input => {
      input.value = expandedIds.join(',');
    });
  }
}
